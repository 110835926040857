<template>
  <div class="position-relative">
    <input
      :id="inputId"
      :type="!showPassword ? 'password' : 'input'"
      class="form-control w-100"
      :class="{ 'is-invalid': hasError }"
      :aria-describedby="helpTextId"
      :placeholder="placeholder"
      :value="value"
      :autocomplete="autocomplete"
      @input="handleChange"
      @blur="handleBlur"
    >
    <button
      class="eye-icon-btn"
      :class="{ 'text-danger': hasError }"
      variant="light"
      @click.prevent="showPassword = !showPassword"
    >
      <eye-off-icon v-if="!showPassword" />
      <eye-icon v-else />
    </button>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons'
import inputMixin from './inputMixin'

export default {
  components: {
    EyeIcon,
    EyeOffIcon
  },
  props: {
    autocomplete: {
      type: String,
      required: false,
      default: 'on'
    }
  },
  mixins: [
    inputMixin
  ],
  data () {
    return {
      showPassword: false
    }
  }
}
</script>

<style lang="scss" scoped>
.eye-icon-btn {
  position: absolute;
  top: 6px;
  right: 0.5rem;
  color: rgba(0, 0, 0, 0.5);

  &,
  &:active,
  &:focus,
  &:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
  }

  svg {
    width: 18px;
    height: auto;
  }
}

.is-invalid {
  background-image: none;
}
</style>
